@import "@fortawesome/fontawesome-free/css/all.min.css";



[class~="navigation"] ul li a {
  color: #fff;
}
* {
  margin-left: 0;
}
*,
.container {
  margin-bottom: 0;
}
body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
[class~="navigation"] ul li a:hover {
  border-left-color: #fff;
}
[class~="navigation"] ul li a {
  text-decoration: none;
}
[class~="navigation"] ul li {
  display: inline-block;
}
.container {
  max-width: 75pc;
}
body {
  line-height: 1.6;
}
body {
  background-color: #fff;
}
* {
  margin-right: 0;
}
*,
.container {
  margin-top: 0;
}
[class~="navigation"] ul li a,
.container {
  padding-left: 1.25pc;
}
body {
  color: #000;
}
.container {
  margin-left: auto;
}
header {
  background-color: #000;
}
header {
  color: #fff;
}
[class~="navigation"] ul li a:hover {
  border-bottom-color: #fff;
}
[class~="navigation"] ul li a {
  padding-bottom: 0.625pc;
}
[class~="navigation"] ul li a:hover {
  border-right-color: #fff;
}
header,
* {
  padding-left: 0;
}
[class~="navigation"] ul li a,
.container {
  padding-right: 1.25pc;
}
.container {
  margin-right: auto;
}
[class~="navigation"] ul li a {
  padding-top: 0.625pc;
}
[class~="navigation"] ul li a {
  border-left-width: 0.125pc;
}
[class~="navigation"] ul li a:hover {
  border-top-color: #fff;
}
*,
.container {
  padding-bottom: 0;
}
.navigation ul {
  list-style: none;
}
header {
  padding-bottom: 0.208333333in;
}
*,
header {
  padding-right: 0;
}
*,
.container {
  padding-top: 0;
}
[class~="navigation"] ul li a {
  border-bottom-width: 0.125pc;
}
header {
  padding-top: 0.208333333in;
}
* {
  box-sizing: border-box;
}
[class~="navigation"] ul li a {
  border-right-width: 0.125pc;
}
header {
  position: fixed;
}
header {
  width: 100%;
}
[class~="navigation"] ul li a {
  border-top-width: 0.125pc;
}
[class~="navigation"] ul li a:hover {
  color: #ccc;
}
[class~="navigation"] ul li a {
  border-left-style: solid;
}
header {
  height: 4.375pc;
}
header {
  z-index: 1000;
}
[class~="navigation"] ul li a {
  border-bottom-style: solid;
}
[class~="navigation"] ul li a {
  border-right-style: solid;
}
[class~="logo"] {
  width: 150px;
}
[class~="logo"] {
  height: auto;
}
[class~="navigation"] ul li a {
  border-top-style: solid;
}
header {
  display: flex;
}
header {
  justify-content: space-between;
}
.navigation ul {
  margin-right: 1.25in;
}
[class~="navigation"] ul li a {
  border-left-color: transparent;
}
[class~="navigation"] ul li a {
  border-bottom-color: transparent;
}
[class~="navigation"] ul li a {
  border-right-color: transparent;
}
[class~="navigation"] ul li a {
  border-top-color: transparent;
}
header {
  align-items: center;
}
[class~="navigation"] ul li a {
  border-image: none;
}
[class~="navigation"] ul li a {
  border-radius: 0.052083333in;
}
[class~="logo"] {
  vertical-align: middle;
}
[class~="logo"] {
  margin-left: 37.5pt;
}
[class~="navigation"] ul li a {
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
[class~="logo"] {
  margin-top: -0.208333333in;
}
[class~="navigation"] {
  text-align: center;
}
[class~="navigation"] {
  flex-grow: 1;
}
[class~="navigation"] ul li {
  margin-right: 30px;
}
@media only screen and (max-width: 768px) {
  [class~="navigation"] {
      position: fixed;
  }
  [class~="navigation"] {
      top: 70px;
  }
  [class~="navigation"] {
      left: 0;
  }
  [class~="navigation"] {
      right: 0;
  }
  [class~="navigation"] {
      background-color: #000;
  }
  [class~="navigation"] ul li,
  [class~="navigation"][class~="active"] {
      display: block;
  }
  [class~="navigation"] {
      padding-left: 15pt;
  }
  [class~="navigation"] {
      padding-bottom: 15pt;
  }
  [class~="navigation"] {
      padding-right: 15pt;
  }
  [class~="navigation"] ul li:last-child {
      margin-bottom: 0;
  }
  [class~="navigation"] {
      padding-top: 15pt;
  }
  [class~="navigation"] {
      z-index: 999;
  }
  [class~="navigation"] {
      text-align: right;
  }
  [class~="navigation"] {
      box-shadow: 0 2px 3pt rgba(0, 0, 0, 0.1);
  }
  [class~="navigation"] {
      display: none;
  }
  [class~="logo"] {
      margin-left: 20px;
  }
  [class~="menu-toggle"] {
      display: block;
  }
}
[class~="menu-toggle"] {
  position: absolute;
}
[class~="menu-toggle"] {
  top: 20px;
}
[class~="menu-toggle"] {
  right: 20px;
}
[class~="menu-toggle"] {
  color: #fff;
}
[class~="menu-toggle"] {
  font-size: 0.208333333in;
}
[class~="menu-toggle"] {
  cursor: pointer;
}
[class~="menu-toggle"] {
  display: none;
}
[class~="menu-toggle"]:hover {
  transform: scale(1.1);
}
@media only screen and (max-width: 768px) {
  .menu-toggle {
      display: block;
  }
}
.hero {
  background-image: url("images/Webys.png");
}
.hero {
  background-size: cover;
}
.hero {
  background-position: center;
}
.hero h1 {
  font-size: 48px;
}
[class~="hero"] [class~="btn"] {
  display: inline-block;
}
.hero {
  padding-left: 0;
}
.hero h1 {
  margin-bottom: 20px;
}
.hero h1 {
  margin-top: 105pt;
}
[class~="hero"] [class~="btn"] {
  background-color: #fff;
}
.hero h1 {
  font-weight: bold;
}
.hero {
  padding-bottom: 1.5625in;
}
.hero {
  padding-right: 0;
}
.hero {
  padding-top: 1.5625in;
}
.hero h1 {
  animation: hero-h1 1s ease-in-out forwards;
}
[class~="about-content"],
.hero {
  text-align: center;
}
[class~="about-content"],
.hero,
[class~="about"] h2 {
  color: #fff;
}
[class~="hero"] p {
  font-size: 15pt;
}
[class~="hero"] p {
  margin-bottom: 12.5pc;
}
.hero {
  height: 8.333333333in;
}
[class~="hero"] p {
  animation: hero-p 1s ease-in-out 0.5s forwards;
}
[class~="hero"] [class~="btn"] {
  color: #000;
}
[class~="hero"] [class~="btn"] {
  padding-left: 0.208333333in;
}
.hero {
  animation: hero-bg 8s ease-in-out infinite alternate;
}
[class~="services"] h2,
.about {
  position: relative;
}
.about {
  height: 100vh;
}
.about,
[class~="services"] h2 {
  overflow: hidden;
}
[class~="hero"] [class~="btn"] {
  padding-bottom: 10px;
}
[class~="about-slider"] img,
[class~="about-content"],
[class~="about-slider"] {
  width: 100%;
}
[class~="about-content"],
[class~="about-slider"] {
  position: absolute;
}
[class~="about-slider"] {
  top: 0;
}
[class~="about-content"],
[class~="about-slider"] {
  left: 0;
}
[class~="about-slider"] img,
[class~="about-slider"] {
  height: 100%;
}
[class~="about-slider"] img {
  object-fit: cover;
}
[class~="hero"] [class~="btn"] {
  padding-right: 0.208333333in;
}
[class~="about"] h2 {
  font-size: 0.375in;
}
[class~="about-slider"],
[class~="services"] {
  display: flex;
}
[class~="about-slider"] {
  animation: slide 15s ease infinite alternate;
}
[class~="about"] h2 {
  margin-bottom: 15pt;
}
[class~="hero"] [class~="btn"],
[class~="about"] p {
  font-size: 1.125pc;
}
[class~="about-content"] {
  bottom: 0;
}
[class~="about"] p {
  line-height: 1.5;
}
[class~="hero"] [class~="btn"] {
  padding-top: 10px;
}
[class~="about-content"] {
  padding-left: 3.125pc;
}
[class~="hero"] [class~="btn"] {
  border-radius: 0.052083333in;
}
[class~="about-content"] {
  padding-bottom: 3.125pc;
}
[class~="about-content"] {
  padding-right: 3.125pc;
}
[class~="about"] p {
  margin-bottom: 11.25pt;
}
[class~="about-content"] {
  padding-top: 3.125pc;
}
[class~="hero"] [class~="btn"] {
  text-decoration: none;
}
[class~="about-content"] {
  background-color: rgba(0, 0, 0, 0.7);
}
[class~="services"] h2 {
  display: inline-block;
}
[class~="services"] {
  flex-direction: column;
}
[class~="services"] {
  justify-content: space-between;
}
[class~="services"] h2 {
  white-space: nowrap;
}
[class~="services"] {
  flex: 1;
}
[class~="services"] {
  background-color: #e0e0e0;
}
[class~="services"] {
  padding-left: 1.25pc;
}
[class~="services"] h2 {
  text-align: center;
}
[class~="services"] {
  padding-bottom: 1.25pc;
}
[class~="services"] {
  padding-right: 1.25pc;
}
[class~="services"] {
  padding-top: 1.25pc;
}
[class~="services"] h2 {
  animation: typewriter 2s steps(40, end) 1s forwards;
}
[class~="hero"] [class~="btn"] {
  transition: background-color 0.3s;
}
.hero .btn:hover {
  background-color: #ccc;
}
@keyframes typewriter {
  from {
      width: 0;
  }
  to {
      width: 100%;
  }
}
[class~="services"] h2::after {
  content: "";
}
[class~="services"] h2::after {
  position: absolute;
}
[class~="services"] h2::after {
  bottom: 0;
}
[class~="services"] h2::after {
  left: 0;
}
[class~="services"] h2::after {
  height: 0.125pc;
}
[class~="services"] h2::after {
  width: 100%;
}
[class~="services"] h2::after {
  background-color: #000;
}
[class~="services"] h2::after {
  animation: blink-caret 0.75s step-end infinite;
}
@keyframes blink-caret {
  from,
  to {
      opacity: 0;
  }
  50% {
      opacity: 1;
  }
}
[class~="project"] img,
.project .project-info,
[class~="service"] img {
  width: 100%;
}
[class~="service"] img {
  height: 2.083333333in;
}
.project .project-info h3 {
  color: #858181;
}
[class~="service"] img,
[class~="project"] img {
  object-fit: cover;
}
[class~="socialMedia"] a i {
  color: #ddd;
}
[class~="service-description"] {
  margin-bottom: 15pt;
}
.project .project-info h3 {
  font-size: 1.2em;
}
.project .project-info {
  position: absolute;
}
[class~="service-description"] {
  animation: slideInLeft 0.5s ease-in-out forwards;
}
[class~="socialMedia"] a i {
  font-size: 13.5pt;
}
[class~="service"] img {
  margin-bottom: 0.9375pc;
}
[class~="portfolio"] h2 {
  margin-bottom: 22.5pt;
}
.project .project-info {
  bottom: 0;
}
[class~="socialMedia"] a i {
  line-height: 26.25pt;
}
.project .project-info h3 {
  margin-bottom: 0;
}
* {
  margin-left: 0;
}
.project:hover .project img {
  transform: scale(1.1);
}
[class~="socialMedia"] a i,
[class~="service"] {
  border-left-width: 0.75pt;
}
[class~="portfolio"] h2 {
  font-size: 2em;
}
.socialMedia a,
[class~="portfolio"] h2 {
  text-align: center;
}
.project .project-info {
  left: 0;
}
[class~="portfolio"] h2 {
  color: #333;
}
.project .project-info h3 {
  transition: text-decoration 0.3s ease-in-out;
}
[class~="project"] img {
  height: 2.604166667in;
}
[class~="project"] [class~="project-info"] a {
  color: #080808;
}
.project .project-info {
  height: 0.729166667in;
}
[class~="project"] img {
  transition: transform 0.3s ease-in-out;
}
.project .project-info {
  background-color: transparent;
}
[class~="service"],
[class~="socialMedia"] a i {
  border-bottom-width: 0.75pt;
}
.socialMedia a {
  width: 0.364583333in;
}
[class~="project"] [class~="project-info"] a,
.socialMedia a {
  text-decoration: none;
}
.project .project-info {
  opacity: 0;
}
.project .project-info {
  transition: opacity 0.3s ease-in-out;
}
section[class~="left"] [class~="contactTitle"] h2 {
  position: relative;
}
[class~="project"] [class~="project-info"] a {
  transition: color 0.3s ease-in-out;
}
.socialMedia a {
  height: 0.364583333in;
}
.project .project-info {
  padding-left: 0.15625in;
}
[class~="project"] [class~="project-info"] a {
  border-left-width: 3pt;
}
.socialMedia a {
  margin-right: 11.25pt;
}
[class~="service"],
[class~="socialMedia"] a i {
  border-right-width: 0.010416667in;
}
section[class~="left"] [class~="contactTitle"] h2 {
  font-size: 1.75pc;
}
[class~="project"] [class~="project-info"] a {
  border-bottom-width: 3pt;
}
[class~="project"] [class~="project-info"] a {
  border-right-width: 3pt;
}
.socialMedia a {
  border-radius: 3.75pt;
}
.project .project-info {
  padding-bottom: 0.15625in;
}
[class~="project"]:hover [class~="project-info"] {
  opacity: 1;
}
.project .project-info {
  padding-right: 0.15625in;
}
.project .project-info,
[class~="service"] {
  border-top-width: 0.010416667in;
}
[class~="project"] [class~="project-info"] a,
[class~="service"] {
  border-left-style: solid;
}
.socialMedia a {
  background-color: dodgerblue;
}
.socialMedia a {
  transition: 0.4s;
}
[class~="project"] [class~="project-info"] a {
  border-top-width: 3pt;
}
.project:hover .project img {
  filter: grayscale(0.8);
}
section[class~="left"] [class~="contactTitle"] h2::before {
  content: "";
}
[class~="messageForm"] input,
section[class~="left"] [class~="contactTitle"] h2 {
  color: #ddd;
}
[class~="service"],
[class~="project"] [class~="project-info"] a {
  border-bottom-style: solid;
}
[class~="project"] [class~="project-info"] h3:hover {
  text-decoration: underline;
}
.project .project-info {
  padding-top: 0.15625in;
}
[class~="messageForm"] input {
  width: 100%;
}
[class~="messageForm"] input {
  font-size: 1.125pc;
}
[class~="service"],
[class~="project"] [class~="project-info"] a {
  border-right-style: solid;
}
.row section.col,
[class~="row"] section[class~="right"] [class~="messageForm"],
.project .project-info {
  display: flex;
}
.project .project-info,
[class~="row"] section[class~="right"] [class~="messageForm"] {
  justify-content: space-between;
}
[class~="messageForm"] input {
  padding-left: 0pc;
}
.project .project-info,
[class~="project"] [class~="project-info"] a,
[class~="service"] {
  border-top-style: solid;
}
[class~="messageForm"] input {
  padding-bottom: 1.5pt;
}
.project .project-info {
  align-items: center;
}
[class~="service"] {
  border-left-color: #ccc;
}
[class~="service"] {
  border-bottom-color: #ccc;
}
[class~="project"] [class~="project-info"] a:hover {
  color: #658186;
}
[class~="messageForm"] input {
  padding-right: 0pc;
}
section[class~="left"] [class~="contactTitle"] h2::before {
  position: absolute;
}
* {
  margin-bottom: 0;
}
[class~="messageForm"] input {
  padding-top: 1.5pt;
}
[class~="project"] [class~="project-info"] a {
  border-left-color: #e0e0e0;
}
[class~="service"] {
  border-right-color: #ccc;
}
[class~="messageForm"] input {
  background-color: #2e2e2e;
}
[class~="iconGroup"] [class~="details"] span:nth-child(1) {
  text-transform: uppercase;
}
[class~="service"] {
  border-top-color: #ccc;
}
[class~="iconGroup"] [class~="details"] span:nth-child(1) {
  color: #ccc;
}
[class~="messageForm"] input {
  border-left-width: medium;
}
[class~="messageForm"] input {
  border-bottom-width: 0.125pc;
}
section[class~="left"] [class~="contactTitle"] h2 {
  display: inline-block;
}
.project .project-info {
  border-top-color: #ddd;
}
[class~="project"] [class~="project-info"] a {
  border-bottom-color: #e0e0e0;
}
[class~="project"] [class~="project-info"] a {
  border-right-color: #e0e0e0;
}
[class~="messageForm"] input {
  border-right-width: medium;
}
.project .project-info,
[class~="service"] {
  border-image: none;
}
[class~="project"] [class~="project-info"] a {
  border-top-color: #e0e0e0;
}
[class~="project"] [class~="project-info"] a {
  border-image: none;
}
[class~="messageForm"] input {
  border-top-width: medium;
}
[class~="project"]:hover {
  border-left-width: 0.75pt;
}
[class~="project"] [class~="project-info"] a {
  border-radius: 3.75pt;
}
[class~="project"] [class~="project-info"] a {
  background-color: #ddd;
}
[class~="service"] {
  padding-left: 1.25pc;
}
[class~="project"]:hover {
  border-bottom-width: 0.75pt;
}
[class~="messageForm"] input {
  border-left-style: none;
}
[class~="service"] {
  padding-bottom: 1.25pc;
}
[class~="project"]:hover,
[class~="messageForm"] input {
  border-bottom-style: solid;
}
section[class~="left"] [class~="contactTitle"] h2::before {
  width: 50%;
}
[class~="project"]:hover {
  border-right-width: 0.75pt;
}
.row section.col {
  flex-direction: column;
}
[class~="messageForm"] input {
  border-right-style: none;
}
[class~="socialMedia"] a i,
[class~="project"]:hover {
  border-top-width: 0.75pt;
}
.row section.left {
  flex-basis: 35%;
}
[class~="service"] {
  padding-right: 1.25pc;
}
.row section.left {
  min-width: 320px;
}
.row section.left {
  margin-right: 0.625in;
}
[class~="project"]:hover,
[class~="socialMedia"] a i {
  border-left-style: solid;
}
[class~="service"] {
  padding-top: 1.25pc;
}
[class~="messageForm"] input {
  border-top-style: none;
}
[class~="project"]:hover {
  border-right-style: solid;
}
[class~="messageForm"] input {
  border-left-color: currentColor;
}
[class~="row"] section[class~="right"] {
  flex-basis: 60%;
}
[class~="project"]:hover {
  border-top-style: solid;
}
[class~="messageForm"] input {
  border-bottom-color: #666;
}
section[class~="left"] [class~="contactInfo"] {
  margin-bottom: 16px;
}
.iconGroup .icon i {
  font-size: 0.208333333in;
}
[class~="service"] {
  background-color: #fff;
}
[class~="messageForm"] input {
  border-right-color: currentColor;
}
.iconGroup .icon i,
[class~="messageForm"] textarea {
  color: #ddd;
}
* {
  margin-right: 0;
}
[class~="messageForm"] input {
  border-top-color: currentColor;
}
.iconGroup .icon i {
  position: absolute;
}
[class~="messageForm"] input {
  border-image: none;
}
[class~="messageForm"] input {
  outline: none;
}
[class~="project"]:hover {
  border-left-color: #ddd;
}
[class~="project"]:hover {
  border-bottom-color: #ddd;
}
.iconGroup .icon i {
  top: 50%;
}
[class~="project"]:hover {
  border-right-color: #ddd;
}
[class~="project"]:hover {
  border-top-color: #ddd;
}
* {
  margin-top: 0;
}
[class~="row"] section[class~="right"] [class~="messageForm"],
section[class~="left"] [class~="socialMedia"] {
  flex-wrap: wrap;
}
[class~="container"] [class~="row"],
[class~="messageForm"] textarea {
  width: 100%;
}
[class~="project"]:hover {
  border-image: none;
}
[class~="service"] {
  box-shadow: 0 0 0.104166667in rgba(0, 0, 0, 0.1);
}
[class~="service"] {
  text-align: center;
}
.col .left {
  flex: 2;
}
.iconGroup .icon i {
  left: 50%;
}
.iconGroup .icon i {
  transform: translate(-50%, -50%);
}
.col .right {
  flex: 1;
}
[class~="container"] [class~="row"],
[class~="contactInfo"] [class~="iconGroup"] {
  display: flex;
}
[class~="messageForm"] textarea,
[class~="iconGroup"] [class~="details"] span {
  font-size: 1.125pc;
}
[class~="messageForm"] textarea {
  padding-left: 0pc;
}
section[class~="left"] [class~="contactTitle"] h2 {
  margin-bottom: 25px;
}
[class~="iconGroup"] [class~="details"] span {
  display: block;
}
[class~="iconGroup"] [class~="details"] span {
  color: #888;
}
[class~="messageForm"] textarea {
  padding-bottom: 1.5pt;
}
[class~="row"] section[class~="right"] [class~="messageForm"] {
  padding-top: 22.5pt;
}
section[class~="left"] [class~="socialMedia"] {
  display: flex;
}
[class~="messageForm"] textarea {
  padding-right: 0pc;
}
[class~="portfolio"] {
  background-color: #f5f5f5;
}
[class~="container"] [class~="row"] {
  justify-content: space-between;
}
section[class~="left"] [class~="socialMedia"] {
  justify-content: flex-start;
}
[class~="portfolio"],
* {
  padding-left: 0;
}
[class~="messageForm"] textarea {
  padding-top: 1.5pt;
}
[class~="messageForm"] textarea,
[class~="socialMedia"] a:hover {
  background-color: #2e2e2e;
}
section[class~="left"] [class~="socialMedia"],
[class~="contactInfo"] [class~="iconGroup"] {
  align-items: center;
}
section[class~="left"] [class~="socialMedia"],
[class~="contactInfo"] [class~="iconGroup"] {
  margin-left: 0pc;
}
section[class~="left"] [class~="socialMedia"] {
  margin-bottom: 15pt;
}
section[class~="left"] [class~="socialMedia"],
[class~="contactInfo"] [class~="iconGroup"] {
  margin-right: 0pc;
}
textarea::-webkit-scrollbar {
  width: 0.052083333in;
}
[class~="messageForm"] textarea {
  border-left-width: medium;
}
section[class~="left"] [class~="socialMedia"] {
  margin-top: 16.5pt;
}
[class~="messageForm"] textarea {
  border-bottom-width: 0.125pc;
}
[class~="container"] [class~="row"] {
  max-width: 11.458333333in;
}
[class~="messageForm"] textarea {
  border-right-width: medium;
}
* {
  padding-bottom: 0;
}
[class~="messageForm"] textarea {
  border-top-width: medium;
}
[class~="messageForm"] textarea {
  border-left-style: none;
}
[class~="messageForm"] textarea,
[class~="socialMedia"] a i,
[class~="iconGroup"] [class~="icon"],
[class~="socialMedia"] a:hover {
  border-bottom-style: solid;
}
section[class~="left"] [class~="contactTitle"] h2::before {
  height: 1px;
}
[class~="portfolio"] {
  padding-bottom: 3.125pc;
}
[class~="portfolio"],
* {
  padding-right: 0;
}
[class~="socialMedia"] a:hover {
  transform: translateY(-5px);
}
[class~="messageForm"] textarea {
  border-right-style: none;
}
section[class~="left"] [class~="contactTitle"] h2::before {
  background-color: #888;
}
[class~="messageForm"] textarea {
  border-top-style: none;
}
[class~="messageForm"] textarea {
  border-left-color: currentColor;
}
[class~="portfolio"] {
  padding-top: 3.125pc;
}
[class~="messageForm"] textarea {
  border-bottom-color: #666;
}
[class~="socialMedia"] a i,
[class~="socialMedia"] a:hover {
  border-right-style: solid;
}
[class~="messageForm"] textarea {
  border-right-color: currentColor;
}
[class~="socialMedia"] a:hover {
  color: dodgerblue;
}
[class~="project-grid"] {
  display: grid;
}
[class~="socialMedia"] a:hover {
  border-left-width: 0.010416667in;
}
[class~="socialMedia"] a i,
[class~="socialMedia"] a:hover {
  border-top-style: solid;
}
[class~="socialMedia"] a:hover {
  border-bottom-width: 0.010416667in;
}
[class~="contactInfo"] [class~="iconGroup"] {
  margin-bottom: 1.5625pc;
}
[class~="socialMedia"] a:hover {
  border-right-width: 0.010416667in;
}
textarea::-webkit-scrollbar-track {
  background-color: #1e1e1e;
}
[class~="socialMedia"] a:hover {
  border-top-width: 0.010416667in;
}
[class~="messageForm"] textarea {
  border-top-color: currentColor;
}
[class~="project-grid"] {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
section.left .contactTitle p {
  font-size: 17px;
}
[class~="messageForm"] textarea {
  border-image: none;
}
[class~="socialMedia"] a i {
  border-left-color: transparent;
}
[class~="socialMedia"] a:hover,
[class~="iconGroup"] [class~="icon"] {
  border-left-style: solid;
}
[class~="messageForm"] textarea {
  outline: none;
}
[class~="project-grid"] {
  grid-gap: 22.5pt;
}
[class~="messageForm"] textarea {
  resize: none;
}
section[class~="left"] [class~="contactTitle"] h2::before {
  top: 120%;
}
[class~="contactInfo"] [class~="iconGroup"] {
  margin-top: 1.5625pc;
}
section[class~="left"] [class~="contactTitle"] h2::before,
[class~="inputGroup"] label {
  left: 0;
}
[class~="messageForm"] textarea {
  height: 165pt;
}
[class~="iconGroup"] [class~="icon"] {
  width: 45px;
}
[class~="messageForm"] textarea {
  display: block;
}
* {
  padding-top: 0;
}
[class~="socialMedia"] a i {
  border-bottom-color: transparent;
}
[class~="iconGroup"] [class~="icon"] {
  height: 2.8125pc;
}
section.left .contactTitle h2::after {
  content: "";
}
[class~="socialMedia"] a i {
  border-right-color: transparent;
}
[class~="iconGroup"] [class~="icon"] {
  border-left-width: 0.125pc;
}
[class~="inputGroup"] label,
section.left .contactTitle h2::after {
  position: absolute;
}
[class~="iconGroup"] [class~="icon"] {
  border-bottom-width: 0.125pc;
}
[class~="row"] section[class~="right"] [class~="inputGroup"] {
  margin-left: 0in;
}
[class~="iconGroup"] [class~="icon"] {
  border-right-width: 0.125pc;
}
* {
  box-sizing: border-box;
}
[class~="socialMedia"] a i {
  border-top-color: transparent;
}
[class~="iconGroup"] [class~="icon"] {
  border-top-width: 0.125pc;
}
[class~="iconGroup"] [class~="icon"],
[class~="socialMedia"] a:hover {
  border-left-color: dodgerblue;
}
[class~="inputGroup"] label {
  bottom: 0.041666667in;
}
.project {
  position: relative;
}
.project {
  background-color: #fff;
}
[class~="inputGroup"] label {
  color: #888;
}
section.left .contactTitle h2::after {
  width: 25%;
}
[class~="inputGroup"] label,
[class~="inputGroup"] button {
  font-size: 0.1875in;
}
[class~="socialMedia"] a i {
  border-image: none;
}
.project {
  box-shadow: 0 2px 0.104166667in rgba(0, 0, 0, 0.1);
}
section.left .contactTitle h2::after {
  height: 2.25pt;
}
[class~="iconGroup"] [class~="icon"],
[class~="socialMedia"] a:hover {
  border-bottom-color: dodgerblue;
}
[class~="inputGroup"] label {
  transition: 0.4s;
}
[class~="inputGroup"] label {
  pointer-events: none;
}
[class~="inputGroup"] button {
  padding-left: 0.166666667in;
}
[class~="inputGroup"] button {
  padding-bottom: 6pt;
}
[class~="iconGroup"] [class~="icon"],
[class~="socialMedia"] a:hover {
  border-right-color: dodgerblue;
}
[class~="socialMedia"] a:hover,
[class~="iconGroup"] [class~="icon"] {
  border-top-color: dodgerblue;
}
[class~="inputGroup"] button,
[class~="iconGroup"] [class~="icon"] {
  border-right-style: solid;
}
[class~="socialMedia"] a i {
  transition-delay: 0.4s;
}
.project {
  border-radius: 0.052083333in;
}
[class~="inputGroup"] button {
  padding-right: 0.166666667in;
}
section.left .contactTitle h2::after,
[class~="inputGroup"] button {
  background-color: dodgerblue;
}
[class~="row"] section[class~="right"] [class~="inputGroup"] {
  margin-bottom: 18px;
}
[class~="inputGroup"] button {
  padding-top: 6pt;
}
[class~="socialMedia"] a:hover {
  border-image: none;
}
section.left .contactTitle h2::after {
  top: calc(120% - 1px);
}
[class~="inputGroup"] button,
[class~="iconGroup"] [class~="icon"] {
  border-top-style: solid;
}
section.left .contactTitle p {
  color: #ccc;
}
[class~="inputGroup"] button {
  color: #ddd;
}
[class~="inputGroup"]:nth-child(4) label {
  top: 2px;
}
[class~="row"] section[class~="right"] [class~="inputGroup"] {
  margin-right: 0in;
}
[class~="inputGroup"] button,
[class~="inputGroup"] button:hover {
  border-left-width: 0.0625pc;
}
.project {
  overflow: hidden;
}
[class~="inputGroup"] button:hover {
  background-color: #2e2e2e;
}
section.left .contactTitle p {
  letter-spacing: 0.010416667in;
}
textarea::-webkit-scrollbar-track {
  border-radius: 15px;
}
[class~="inputGroup"] button:hover,
[class~="inputGroup"] button {
  border-bottom-width: 0.0625pc;
}
.inputGroup input:focus ~ label,
[class~="inputGroup"] input:valid ~ label {
  transform: translateY(-30px);
}
[class~="socialMedia"] a:hover i,
[class~="inputGroup"] button:hover {
  color: dodgerblue;
}
[class~="inputGroup"] button:hover {
  box-shadow: 0in 0in 11.25pt rgba(0, 0, 0, 0.3);
}
[class~="inputGroup"] button,
[class~="inputGroup"] button:hover {
  border-right-width: 0.0625pc;
}
[class~="row"] section[class~="right"] [class~="inputGroup"] {
  margin-top: 18px;
}
section.left .contactTitle h2::after {
  left: 0;
}
.project {
  transition: all 0.3s ease-in-out;
}
[class~="inputGroup"] button:hover,
[class~="inputGroup"] button {
  border-top-width: 0.0625pc;
}
* {
  font-family: "Poppins", sans-serif;
}
section.left .contactTitle p {
  line-height: 1.2;
}
[class~="inputGroup"] input:valid ~ label,
.inputGroup input:focus ~ label {
  font-size: 1pc;
}
[class~="inputGroup"] button,
[class~="inputGroup"] button:hover {
  border-left-style: solid;
}
[class~="inputGroup"] button:hover,
[class~="inputGroup"] button {
  border-bottom-style: solid;
}
section.left .contactTitle p {
  padding-bottom: 16.5pt;
}
[class~="inputGroup"] button {
  border-left-color: transparent;
}
[class~="inputGroup"] button {
  border-bottom-color: transparent;
}
[class~="iconGroup"] [class~="icon"] {
  border-image: none;
}
[class~="container1"],
[class~="container1"] {
  width: 100%;
}
[class~="row"] section[class~="right"] [class~="inputGroup"],
[class~="iconGroup"] [class~="icon"] {
  position: relative;
}
[class~="inputGroup"] button {
  border-right-color: transparent;
}
[class~="iconGroup"] [class~="icon"] {
  border-radius: 50%;
}
[class~="iconGroup"] [class~="icon"] {
  margin-right: 1.25pc;
}
[class~="inputGroup"] button:hover {
  border-right-style: solid;
}
[class~="inputGroup"] button {
  border-top-color: transparent;
}
[class~="inputGroup"] button {
  border-image: none;
}
[class~="inputGroup"] button:hover {
  border-top-style: solid;
}
[class~="messageForm"] [class~="halfWidth"] {
  flex-basis: 48%;
}
[class~="messageForm"] [class~="fullWidth"] {
  flex-basis: 100%;
}
[class~="inputGroup"] button:hover {
  border-left-color: dodgerblue;
}
[class~="inputGroup"] button:hover {
  border-bottom-color: dodgerblue;
}
[class~="inputGroup"] button:hover {
  border-right-color: dodgerblue;
}
[class~="container1"],
[class~="container1"] {
  min-height: 100vh;
}
[class~="inputGroup"] button {
  border-radius: 25px;
}
[class~="inputGroup"] button:hover {
  border-top-color: dodgerblue;
}
[class~="inputGroup"] button {
  outline: none;
}
textarea::-webkit-scrollbar-thumb {
  background-color: dodgerblue;
}
[class~="inputGroup"] button:hover {
  border-image: none;
}
[class~="inputGroup"] button {
  cursor: pointer;
}
[class~="inputGroup"] button {
  box-shadow: 0px 6pt 11.25pt rgba(0, 0, 0, 0.3);
}
[class~="inputGroup"] button {
  transition: 0.4s;
}
[class~="container1"] {
  display: flex;
}
[class~="inputGroup"] textarea:valid ~ label,
[class~="inputGroup"] textarea:focus ~ label {
  transform: translateY(-30px);
}
[class~="container1"] {
  justify-content: center;
}
[class~="container1"] {
  align-items: center;
}
[class~="container1"] {
  padding-left: 8%;
}
[class~="container1"] {
  padding-bottom: 1.875pc;
}
[class~="inputGroup"] textarea:focus ~ label,
[class~="inputGroup"] textarea:valid ~ label {
  font-size: 1pc;
}
[class~="container1"] {
  padding-right: 8%;
}
[class~="container1"] {
  padding-top: 1.875pc;
}
[class~="container1"] {
  background-color: #2e2e2e;
}
[class~="container1"] {
  color: white;
}
textarea::-webkit-scrollbar-thumb {
  border-radius: 11.25pt;
}
@media (max-width: 1100px) {
  [class~="messageForm"] [class~="halfWidth"] {
      flex-basis: 100%;
  }
}
@media (max-width: 900px) {
  [class~="row"] section[class~="left"],
  [class~="row"] section[class~="right"] {
      flex-basis: 100%;
  }
  [class~="row"] section[class~="right"],
  [class~="row"] section[class~="left"] {
      margin-left: 0pc;
  }
  [class~="row"] section[class~="right"],
  [class~="row"] section[class~="left"] {
      margin-bottom: 0pc;
  }
  [class~="container"] [class~="row"] {
      flex-wrap: wrap;
  }
  [class~="row"] section[class~="right"],
  [class~="row"] section[class~="left"] {
      margin-right: 0pc;
  }
  [class~="row"] section[class~="left"],
  [class~="row"] section[class~="right"] {
      margin-top: 0pc;
  }
}
