@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Reset default browser padding and margin */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
